<template>
  <div class="mt-8">
    <v-row class="mb-4">
      <v-col
        cols="12"
        md="12"
        class="headline pa-2"
        style="border-bottom:4px solid #fecf0c"
      >
        <h2>Neue Berechnung "Katze-Krankenversicherung" starten
        <span class="hidden-xs-only">&nbsp;|&nbsp;inkl. AntragAnnahmecheck</span></h2>
      </v-col>
    </v-row>
    <div style="max-width: 888px">
      <v-row class="my-4">
        <v-col cols="12" md="3">Wie heißt Ihre Katze?</v-col>
        <v-col cols="12" md="9" class="pt-0">
          <v-text-field
            v-model="formRechner.name"
            label="Name Ihrer Katze"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="my-4">
        <v-col cols="12" md="3">Geschlecht Ihrer Katze?</v-col>
        <v-col cols="12" md="3" class="pt-0">
          <v-radio-group v-model="formRechner.geschlecht">
            <v-radio :value="1" label="Männlich" />
            <v-radio :value="2" label="Weiblich" />
          </v-radio-group>
        </v-col>
        <v-col cols="12" md="2">Ihre Postleitzahl</v-col>
        <v-col cols="12" md="3" class="pt-0">
          <v-text-field
            v-model="formRechner.plz"
            :error="!!formErrors.plz"
            :error-messages="formErrors.plz"
            width="100px"
            label="Postleitzahl"
          />
        </v-col>
      </v-row>
   
      <v-row class="my-4">
        <v-col cols="12" md="3">Welche Rasse hat Ihre Katze?</v-col>
        <v-col cols="12" md="9" class="pt-0">
          <multiselect
            v-if="rassen && !weissnicht"
            v-model="chosen"
            :options="rassen"
            placeholder="Wählen Sie bitte eine Rasse aus"
            deselect-label="Enter um Rasse zu löschen"
            select-label="Enter um Rasse auszuwählen"
          ></multiselect>
          <div v-if="formErrors.rasse" class="text-red">{{ formErrors.rasse }}</div>
          <v-checkbox
            v-model="weissnicht"
            value="1"
            label="Ich kenne die Rasse nicht bzw. ist keine Rassenkatze"
          />
        </v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="3">Geburtstag Ihrer Katze</v-col>
        <v-col cols="12" md="9" class="pt-0">
          <InputGeburtstagSelect
            @update:year="yearChanged"
            @update:month="monthChanged"
            @update:day="dayChanged"
            v-model:year="jahr"
            v-model:month="monat"
            v-model:day="tag"
            :error="!!formErrors.geburtstag"
            :error-messages="formErrors.geburtstag"
          />
          <div v-if="formErrors.geburtstag" class="text-red">{{ formErrors.geburtstag }}</div>
        </v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="6">Ist Ihre Katze aktuell gesund?</v-col>
        <v-col cols="12" md="6" class="pt-0">
          <v-radio-group v-model="formRechner.ber_annahme_krank">
            <v-row>
              <v-col cols="12" md="6"><v-radio :value="2" label="Ja" /></v-col>
              <v-col cols="12" md="6"><v-radio :value="1" label="Nein" /></v-col>
            </v-row>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="6">Wurde Ihre Katze in den letzten 6 Monaten operiert?</v-col>
        <v-col cols="12" md="6" class="pt-0">
          <v-radio-group v-model="formRechner.ber_annahme_op6m">
            <v-row>
              <v-col cols="12" md="6"><v-radio :value="1" label="Ja" /></v-col>
              <v-col cols="12" md="6"><v-radio :value="2" label="Nein" /></v-col>
            </v-row>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="6">Ist Ihre Katze kastriert?</v-col>
        <v-col cols="12" md="6" class="pt-0">
          <v-radio-group v-model="formRechner.kastration">
            <v-row>
              <v-col cols="12" md="6"><v-radio :value="1" label="Ja" /></v-col>
              <v-col cols="12" md="6"><v-radio :value="0" label="Nein" /></v-col>
            </v-row>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="6">Ist Ihre Katze gechiped?</v-col>
        <v-col cols="12" md="6" class="pt-0">
          <v-radio-group v-model="formRechner.chip">
            <v-row>
              <v-col cols="12" md="6"><v-radio :value="1" label="Ja" /></v-col>
              <v-col cols="12" md="6"><v-radio :value="0" label="Nein" /></v-col>
            </v-row>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="6">Ist Ihre Katze ein Freigänger?</v-col>
        <v-col cols="12" md="6" class="pt-0">
          <v-radio-group v-model="formRechner.outdoor">
            <v-row>
              <v-col cols="12" md="6"><v-radio :value="1" label="Ja" /></v-col>
              <v-col cols="12" md="6"><v-radio :value="0" label="Nein" /></v-col>
            </v-row>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="6">Ist Ihre Katze geimpft?</v-col>
        <v-col cols="12" md="6" class="pt-0">
          <v-radio-group v-model="formRechner.impfung">
            <v-row>
              <v-col cols="12" md="6"><v-radio :value="1" label="Ja" /></v-col>
              <v-col cols="12" md="6"><v-radio :value="0" label="Nein" /></v-col>
            </v-row>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="6">Soll Homöopathie mitversichert werden?</v-col>
        <v-col cols="12" md="6" class="pt-0">
          <v-radio-group v-model="formRechner.homoe">
            <v-row>
              <v-col cols="12" md="6"><v-radio :value="1" label="Ja" /></v-col>
              <v-col cols="12" md="6"><v-radio :value="0" label="Nein" /></v-col>
            </v-row>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row class="my-4" v-if="showfehler">
        <v-col cols="12" md="3"><b class="text-red">Achtung</b></v-col>
        <v-col cols="12" md="9">{{ fehlerarray }}</v-col>
      </v-row>

      <v-btn
        class="nocaps mr-4 text-white"
        color="#fecf0c"
        depressed
        tile
        style="font-size: 24px;width:100%;height: 48px;"
        @click="getBerechnungErgebnis"
      >hier klicken und eigenen Vergleich starten</v-btn>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, watch, onMounted, getCurrentInstance } from 'vue';
import InputGeburtstagSelect from '@/components/rechner/InputGeburtstagSelect.vue';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import BaseCalls from '@/services/BaseCalls';
import { useRouter } from 'vue-router';
import { useLoaderStore } from '@/stores/loader';
import { useBerechnungStore } from '@/stores/rechner';

const { proxy } = getCurrentInstance();
const loaderStore = useLoaderStore();
const router = useRouter();
const rechnerStore = useBerechnungStore();

const showfehler = ref(false);
const fehlerarray = ref('');
const weissnicht = ref(false);
const chosen = ref('');
const tag = ref(new Date().getDate());
const monat = ref(new Date().getMonth() + 1);
const jahr = ref(new Date().getFullYear());
const dateChanged = ref(0);
const rassen = ref([]);
const fullrassen = ref([]);
const submitted = ref(false);
const formErrors = reactive({});

const formRechner = reactive({
  action: 'getBerechnungErgebnis',
  alter: 0,
  rasse: '0',
  type: 'katze',
  tierart: 2,
  geschlecht: 1,
  kastration: 0,
  plz: '',
  chip: 1,
  impfung: 1,
  name: '',
  geburtstag: '',
  homoe: 0,
  outdoor: 0,
  ber_annahme_op6m: 2,
  ber_annahme_krank: 2,
});

function setLoading(loading) {
  loaderStore.setLoader(loading);
}

function dayChanged(day) {
  tag.value = day;
  calcAge();
}

function monthChanged(month) {
  monat.value = month;
  calcAge();
}

function yearChanged(year) {
  jahr.value = year;
  calcAge();
}

function calcAge() {
  let age = calcAgeDate(tag.value, monat.value, jahr.value);
  if (age < 0) {
    age = 0;
  }
  dateChanged.value += 1;
  formRechner.alter = age;
  formRechner.geburtstag = `${jahr.value}-${monat.value}-${tag.value}`;
}

function calcAgeDate(d, m, y) {
  let dateNow = new Date();
  let age = dateNow.getFullYear() - parseInt(y);
  if (
    dateNow.getMonth() + 1 < m ||
    (dateNow.getMonth() + 1 === m && dateNow.getDate() < d)
  ) {
    age--;
  }
  return age;
}

function searchStringInArray(chosenValue) {
  if (rassen.value && fullrassen.value) {
    for (let j = 0; j < rassen.value.length; j++) {
      if (fullrassen.value[j].ras_name.match(chosenValue)) {
        return fullrassen.value[j].ras_id;
      }
    }
  }
  return 0;
}

async function getBerechnungErgebnis() {
  if (chosen.value && !weissnicht.value) {
    formRechner.rasse = searchStringInArray(chosen.value);
  }
  // Set 'submitted' to true when the user clicks the button
  submitted.value = true;

  if (checkFormFehler()) {
    setLoading(true);
    const resp = await BaseCalls.postRechner(formRechner);
    if (resp.data && resp.data.token) {
      setLoading(false);
      router.push({
        name: 'vergleich',
        params: {
          token: resp.data.token,
        },
      });
    } else {
      setLoading(false);
    }
  }
}

function checkFormFehler() {
  let valid = true;

  // Validate PLZ (Postal Code)
  if (!formRechner.plz || formRechner.plz.length < 3) {
    formErrors.plz =
      'Bitte geben Sie Ihre PLZ ein. Einige Anbieter kalkulieren den Preis auch anhand der PLZ.';
    valid = false;
  } else {
    formErrors.plz = '';
  }

  // Validate Geburtstag (Birthday)
  if (dateChanged.value <= 1) {
    formErrors.geburtstag =
      'Bitte geben Sie den Geburtstag Ihrer Katze ein (geschätzt)';
    valid = false;
  } else {
    formErrors.geburtstag = '';
  }

  // Validate Rasse (Breed)
  if (!weissnicht.value && (!formRechner.rasse || formRechner.rasse === '0') && chosen.value == "") {
    formErrors.rasse = 'Bitte geben Sie die Rasse Ihrer Katze ein';
    valid = false;
  } else {
    formErrors.rasse = '';
  }

  // Update general error display
  if (valid) {
    showfehler.value = false;
    fehlerarray.value = '';
  } else {
    showfehler.value = true;
    // Set fehlerarray.value to the first error message encountered
    for (let key in formErrors) {
      if (formErrors[key]) {
        fehlerarray.value = formErrors[key];
        break;
      }
    }
  }

  return valid;
}

async function getRassen() {
  const select = {
    action: 'getRassenKatze',
  };
  const resp = await BaseCalls.postRechner(select);
  if (resp.data) {
    if (resp.data.rassen) {
      rassen.value = resp.data.rassen;
    }
    if (resp.data.fullrassen) {
      fullrassen.value = resp.data.fullrassen;
    }
  }
}

watch(
  () => rechnerStore.berechnung,
  (newVal) => {
    if (newVal && newVal.ber_tierart == 2) {
      // Update formRechner with values from rechnerStore.berechnung
      Object.assign(formRechner, {
        alter: newVal.ber_alter,
        name: newVal.ber_name,
        rasse: newVal.ber_rasse_id,
        geburtstag: newVal.ber_geburtstag,
        plz: newVal.ber_plz,
        geschlecht: newVal.ber_geschlecht,
        ber_annahme_krank: newVal.ber_annahme_krank,
        ber_annahme_op6m: newVal.ber_annahme_op6m,
        kastration: newVal.ber_kastration,
        chip: newVal.ber_chiped,
        impfung: newVal.ber_impfung,
        homoe: newVal.ber_homoe,
        outdoor: newVal.ber_outdoor,
      });

      // Check if ber_geburtstag is defined and a valid string
      if (newVal.ber_geburtstag && typeof newVal.ber_geburtstag === 'string') {
        // Split the date string safely
        const [year, month, day] = newVal.ber_geburtstag.split('-');
        if (year && month && day) {
          jahr.value = parseInt(year);
          monat.value = parseInt(month);
          tag.value = parseInt(day);
        } else {
          console.error('Invalid date format in ber_geburtstag:', newVal.ber_geburtstag);
        }
      } else {
        console.error('ber_geburtstag is undefined or not a string');
      }

      // Recalculate age based on the updated date
      calcAge();
    }
  },
  { immediate: true }
);

onMounted(() => {
  getRassen();
  proxy.$updateDocumentHead(
    'Katzen-Krankenversicherungen vergleichen',
    'Katzen-Krankenversicherungen vergleichen'
  );
});

// Updated watchers to only trigger after submission
watch(() => formRechner.plz, () => {
  if (submitted.value) checkFormFehler();
});
watch(() => dateChanged.value, () => {
  if (submitted.value) checkFormFehler();
});
watch(() => chosen.value, () => {
  if (submitted.value) checkFormFehler();
});
</script>