<template>
  <div class="mt-8">
  
    <v-row class="mb-4">
      <v-col
        cols="12"
        md="12"
        class="headline pa-2"
        style="border-bottom:4px solid #d84315"
      >
        <h2>Neue Berechnung "Hunde-Krankenversicherung" starten
        <span class="hidden-xs-only"
          >&nbsp;|&nbsp; inkl. AntragAnnahmecheck
        </span></h2></v-col
      >
    </v-row>
    <div style="max-width: 888px">
      <v-row class="my-4">
        <v-col cols="12" md="3">Wie heisst Ihr Hund?</v-col>
        <v-col cols="12" md="9" class="pt-0">
          <v-text-field
            v-model="formRechner.name"
            label="Name Ihres Hundes"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row class="my-4">
        <v-col cols="12" md="3">Geschlecht Ihres Hundes?</v-col>
        <v-col cols="12" md="3" class="pt-0">
          <v-radio-group v-model="formRechner.geschlecht">
            <v-radio :value="1" label="Männlich" />
            <v-radio :value="2" label="Weiblich" />
          </v-radio-group>
        </v-col>
        <v-col cols="12" md="2">Ihre Postleitzahl</v-col>
        <v-col cols="12" md="3" class="pt-0">
          <v-text-field
            v-model="formRechner.plz"
            :error="!!formErrors.plz"
            :error-messages="formErrors.plz"
            width="100px"
            label="Postleitzahl"
          />
        </v-col>
      </v-row>
   
      <v-row class="my-4">
        <v-col cols="12" md="3">Ist Ihr Hund ein Mischling?</v-col>
        <v-col cols="12" md="9" class="pt-0">
        
          <v-radio-group v-model="formRechner.mischling">
            <v-row>
              <v-col cols="12" md="4">
            <v-radio :value="0" label="Nein, er ist reinrassig" />
              </v-col>

              <v-col cols="12" md="6">
            <v-radio :value="1" label="Ja, er ist Mischling" /> 
          </v-col>
            </v-row></v-radio-group
        ></v-col>
      </v-row>
      <v-row class="my-4">
        <v-col cols="12" md="3">Hunderasse?</v-col>
        <v-col cols="12" md="9" class="pt-0">
          <multiselect
            deselect-label="Enter um Rasse zu löschen"
            select-label="Enter um Rasse auszuwählen"
            placeholder="Wählen Sie bitte eine Rasse aus"
            v-if="rassen && (!weissnicht || formRechner.mischling == 0)"
            v-model="chosen"
            :options="rassen"
          
          ></multiselect>
          <div v-if="formErrors.rasse" class="text-red">{{ formErrors.rasse }}</div>
       
          <div
            class="my-4"
            v-if="
              chosen == '' &&
                ((rassen && !weissnicht) || formRechner.mischling == 0)
            "
          >
            {{ chosen }}
            <v-chip @click="setChosen(701)"
              >Labrador
              <b v-if="chosenrasse == 701" class="text-green">
                &nbsp;| ausgewählt</b
              ></v-chip
            >
            <v-chip @click="setChosen(439)" class="ml-2 my-2"
              >Französische Bulldogge
              <b v-if="chosenrasse == 439" class="text-green">
                &nbsp;| ausgewählt</b
              ></v-chip
            >
            <v-chip @click="setChosen(294)" class="ml-2 my-2"
              >Chihuahua
              <b v-if="chosenrasse == 294" class="text-green">
                &nbsp;| ausgewählt</b
              ></v-chip
            >
            <v-chip @click="setChosen(82)" class="ml-2 my-2"
              >Australian Shepherd
              <b v-if="chosenrasse == 82" class="text-green">
                &nbsp;| ausgewählt</b
              ></v-chip
            >
            <v-chip @click="setChosen(602)" class="ml-2 my-2"
              >Jack Russell Terrier
              <b v-if="chosenrasse == 602" class="text-green">
                &nbsp;| ausgewählt</b
              ></v-chip
            >
            <v-chip @click="setChosen(751)" class="ml-2 my-2"
              >Malteser
              <b v-if="chosenrasse == 751" class="text-green">
                &nbsp;| ausgewählt</b
              ></v-chip
            >

            <v-chip @click="setChosen(1263)" class="ml-2 my-2"
              >Yorkshire Terrier
              <b v-if="chosenrasse == 1263" class="text-green">
                &nbsp;| ausgewählt</b
              ></v-chip
            >
          </div>
          <v-checkbox
            v-if="formRechner.mischling == 1"
            v-model="weissnicht"
            value="1"
            label="Ich kenne die Rasse nicht"
          />
        </v-col>
      </v-row>

      <v-row class="my-4" v-if="formRechner.mischling == 1 && weissnicht != 1">
        <v-col cols="12" md="3">2. Hunderasse?</v-col>
        <v-col cols="12" md="9" class="pt-0">
          <multiselect  deselect-label="Enter um Rasse zu löschen"   select-label="Enter um Rasse auszuwählen"  placeholder="Wählen Sie bitte eine 2. Rasse aus"    v-if="rassen && (!weissnicht || formRechner.mischling == 0)" v-model="chosen2" :options="rassen"></multiselect>
      
          <v-checkbox
            v-model="weissnicht2"
            value="1"
            label="Ich kenne die 2.  Rasse nicht"
          />
        </v-col>
      </v-row>

      <div v-if="weissnicht == 1 || formRechner.mischling == 1">
        <v-row class="my-4">
          <v-col cols="12" md="3">Wie groß ist Ihr Hund?</v-col>
          <v-col cols="12" md="9" class="pt-0">
            <v-radio-group v-model="formRechner.hoehe">
              <v-radio :value="1" label="0 - 44 cm" />
              <v-radio :value="2" label="45 cm - 120 cm" /> </v-radio-group
          ></v-col>
        </v-row>
      </div>

      <v-row class="my-4">
        <v-col cols="12" md="3">Geburtstag Ihres Hundes</v-col>
        <v-col cols="12" md="9" class="pt-0">
          <InputGeburtstagSelect
            v-on:update:year="yearChanged"
            v-on:update:month="monthChanged"
            v-on:update:day="dayChanged"
            v-model:year="jahr"
            v-model:month="monat"
            v-model:day="tag"
            :error="!!formErrors.geburtstag"
            :error-messages="formErrors.geburtstag"
        />
        <div v-if="formErrors.geburtstag" class="text-red">{{ formErrors.geburtstag }}</div>
        </v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="3">Ist Ihr Hund aktuell gesund?</v-col>
        <v-col cols="12" md="3" class="pt-0">
          <v-radio-group v-model="formRechner.ber_annahme_krank">
            <v-row>
              <v-col cols="12" md="6"><v-radio :value="2" label="Ja"/></v-col>
              <v-col cols="12" md="6"
                ><v-radio :value="1" label="Nein" />
              </v-col>
            </v-row> </v-radio-group
        ></v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="3"
          >Wurde Ihr Hund in den letzten 6 Monaten operiert?</v-col
        >
        <v-col cols="12" md="3" class="pt-0">
          <v-radio-group v-model="formRechner.ber_annahme_op6m">
            <v-row>
              <v-col cols="12" md="6"><v-radio :value="1" label="Ja"/></v-col>
              <v-col cols="12" md="6"
                ><v-radio :value="2" label="Nein" />
              </v-col>
            </v-row> </v-radio-group
        ></v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="3">Wird Ihr Hund zur Jagd benutzt?</v-col>
        <v-col cols="12" md="3" class="pt-0">
          <v-radio-group v-model="formRechner.jagd">
            <v-row>
              <v-col cols="12" md="6"><v-radio :value="1" label="Ja"/></v-col>
              <v-col cols="12" md="6"><v-radio :value="0" label="Nein"/></v-col>
            </v-row> </v-radio-group
        ></v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="3">Ist Ihr Hund kastriert?</v-col>
        <v-col cols="12" md="3" class="pt-0">
          <v-radio-group v-model="formRechner.kastration">
            <v-row>
              <v-col cols="12" md="6"><v-radio :value="1" label="Ja"/></v-col>
              <v-col cols="12" md="6"><v-radio :value="0" label="Nein"/></v-col>
            </v-row> </v-radio-group
        ></v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="3">Ist Ihr Hund gechiped?</v-col>
        <v-col cols="12" md="3" class="pt-0">
          <v-radio-group v-model="formRechner.chip">
            <v-row>
              <v-col cols="12" md="6"><v-radio :value="1" label="Ja"/></v-col>
              <v-col cols="12" md="6"><v-radio :value="0" label="Nein"/></v-col>
            </v-row> </v-radio-group
        ></v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="3">Ist Ihr Hund geimpft?</v-col>
        <v-col cols="12" md="3" class="pt-0">
          <v-radio-group v-model="formRechner.impfung">
            <v-row>
              <v-col cols="12" md="6"><v-radio :value="1" label="Ja"/></v-col>
              <v-col cols="12" md="6"><v-radio :value="0" label="Nein"/></v-col>
            </v-row> </v-radio-group
        ></v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="3">Soll Homöopathie mitversichert werden?</v-col>
        <v-col cols="12" md="3" class="pt-0">
          <v-radio-group v-model="formRechner.homoe">
            <v-row>
              <v-col cols="12" md="6"><v-radio :value="1" label="Ja"/></v-col>
              <v-col cols="12" md="6"><v-radio :value="0" label="Nein"/></v-col>
            </v-row> </v-radio-group
        ></v-col>
      </v-row>

      <v-row class="my-4" v-if="showfehler">
        <v-col cols="12" md="3"><b class="text-red">Achtung</b></v-col>
        <v-col cols="12" md="9">{{ fehlerarray }}</v-col>
      </v-row>

      <v-btn
        class="nocaps mr-4 text-white"
        color="#d84315"
        depressed
        tile
        style="font-size: 24px;width:100%;height: 48px;"
        @click="getBerechnungErgebnis()"
        >hier klicken und eigenen Vergleich starten</v-btn
      ><br /><br />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive, getCurrentInstance, watch } from "vue";
import InputGeburtstagSelect from "@/components/rechner/InputGeburtstagSelect.vue";
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import BaseCalls from "@/services/BaseCalls";
import { useRouter } from "vue-router";
import { useLoaderStore } from "@/stores/loader";
import { useBerechnungStore } from '@/stores/rechner';

const rechnerStore = useBerechnungStore();
const { proxy } = getCurrentInstance();
const loaderStore = useLoaderStore();
const router = useRouter();

// Keep showfehler and fehlerarray
const showfehler = ref(false);
const fehlerarray = ref("");

// Initialize formErrors for field-specific errors
const formErrors = reactive({});

// Initialize 'submitted' flag to track form submission
const submitted = ref(false);

const rassen = ref(false);
const fullrassen = ref(false);
const weissnicht = ref(false);
const weissnicht2 = ref(false);
const chosen = ref("");
const chosen2 = ref("");
const chosenrasse = ref(0);
const tag = ref(new Date().getDate());
const monat = ref(new Date().getMonth() + 1);
const jahr = ref(new Date().getFullYear());

const dateChanged = ref(0);

const formRechner = reactive({
  action: "getBerechnungErgebnis",
  alter: 0,
  rasse: 0,
  type: "hunde",
  kastration: 0,
  tierart: 1,
  mischling: 0,
  plz: "",
  rasse2: 0,
  hoehe: 0,
  chip: 1,
  impfung: 1,
  jagd: 0,
  name: "",
  geburtstag: "",
  homoe: 0,
  geschlecht: 1,
  ber_annahme_op6m: 2,
  ber_annahme_krank: 2,
});

function setLoading(loading) {
  loaderStore.setLoader(loading);
}

function dayChanged(day) {
  tag.value = day;
  calcAge();
}

function monthChanged(month) {
  monat.value = month;
  calcAge();
}

function yearChanged(year) {
  jahr.value = year;
  calcAge();
}

function calcAge() {
  let age = calcAgeDate(tag.value, monat.value, jahr.value);
  if (age < 0) {
    age = 0;
  }
  dateChanged.value += 1;
  formRechner.alter = age;
  formRechner.geburtstag = `${jahr.value}-${monat.value}-${tag.value}`;
}

function calcAgeDate(d, m, y) {
  let dateNow = new Date();
  let age = dateNow.getFullYear() - parseInt(y);
  if (
    dateNow.getMonth() + 1 < m ||
    (dateNow.getMonth() + 1 === m && dateNow.getDate() < d)
  ) {
    age--;
  }
  return age;
}

// Updated checkFormFehler function
function checkFormFehler() {
  let valid = true;

  // Validate PLZ (Postal Code)
  if (formRechner.plz === "" || formRechner.plz.length < 3) {
    formErrors.plz =
      "Bitte geben Sie Ihre PLZ ein. Einige Anbieter kalkulieren den Preis auch anhand der PLZ.";
    valid = false;
  } else {
    formErrors.plz = "";
  }

  // Validate Geburtstag (Birthday)
  if (dateChanged.value <= 1) {
    formErrors.geburtstag =
      "Bitte geben Sie den Geburtstag Ihres Hundes ein (geschätzt)";
    valid = false;
  } else {
    formErrors.geburtstag = "";
  }

  // Validate Höhe (Height) for Mischling (Mixed Breed)
  if (formRechner.mischling === 1 && formRechner.hoehe === 0) {
    formErrors.hoehe = "Bitte geben Sie die Höhe Ihres Mischlings ein";
    valid = false;
  } else {
    formErrors.hoehe = "";
  }

  // Validate Rasse (Breed) for Purebred
  if (formRechner.mischling === 0 && formRechner.rasse === 0 ) {
    formErrors.rasse = "Bitte geben Sie die Rasse Ihres Hundes ein";
    valid = false;
  } else {
    formErrors.rasse = "";
  }

  // Update general error display
  if (valid) {
    showfehler.value = false;
    fehlerarray.value = "";
  } else {
    showfehler.value = true;
    // Set fehlerarray.value to the first error message encountered
    for (let key in formErrors) {
      if (formErrors[key]) {
        fehlerarray.value = formErrors[key];
        break;
      }
    }
  }

  return valid;
}

function searchStringInArray(chosen) {
  for (var j = 0; j < rassen.value.length; j++) {
    if (fullrassen.value[j].ras_name.match(chosen))
      return fullrassen.value[j].ras_id;
  }
  return 0;
}

async function getBerechnungErgebnis() {
  if (chosenrasse.value > 0) {
    formRechner.rasse = chosenrasse.value;
    if (chosen.value) {
      formRechner.rasse = searchStringInArray(chosen.value);
    }
    if (chosen2.value && !weissnicht2.value) {
      formRechner.rasse2 = searchStringInArray(chosen2.value);
    }
  } else {
    if (chosen.value && !weissnicht.value) {
      formRechner.rasse = searchStringInArray(chosen.value);
    }
    if (chosen2.value && !weissnicht2.value) {
      formRechner.rasse2 = searchStringInArray(chosen2.value);
    }
  }

  // Set 'submitted' to true when the user clicks the button
  submitted.value = true;

  if (checkFormFehler()) {
    setLoading(true);
    const resp = await BaseCalls.postRechner(formRechner);
    if (resp.data && resp.data.token) {
      setLoading(false);
      router.push({
        name: "vergleich",
        params: {
          token: resp.data.token,
        },
      });
    } else {
      setLoading(false);
    }
  }
}

function setChosen(id) {
  chosenrasse.value = id;
  formRechner.rasse = searchStringInArray(chosen.value);
}

async function getRassen() {
  let select = {
    action: "getRassen",
  };

  const resp = await BaseCalls.postRechner(select);
  if (resp.data.rassen) {
    rassen.value = resp.data.rassen;
    fullrassen.value = resp.data.fullrassen;
  }
}

watch(
  () => rechnerStore.berechnung,
  (newVal) => {
    if (newVal && newVal.ber_tierart == 1) {
      // Update formRechner with values from rechnerStore.berechnung
      Object.assign(formRechner, {
        alter: newVal.ber_alter,
        name: newVal.ber_name,
        rasse: newVal.ber_rasse_id,
        rasse2: newVal.ber_rasse_id_2,
        hoehe: newVal.ber_hoehe,
        geburtstag: newVal.ber_geburtstag,
        mischling: newVal.ber_mischling,
        plz: newVal.ber_plz,
        geschlecht: newVal.ber_geschlecht,
        ber_annahme_krank: newVal.ber_annahme_krank,
        ber_annahme_op6m: newVal.ber_annahme_op6m,
        jagd: newVal.ber_jagd,
        kastration: newVal.ber_kastration,
        chip: newVal.ber_chiped,
        impfung: newVal.ber_impfung,
        homoe: newVal.ber_homoe,
      });

      // Check if ber_geburtstag is defined and a valid string
      if (
        newVal.ber_geburtstag &&
        typeof newVal.ber_geburtstag === "string"
      ) {
        // Split the date string safely
        const [year, month, day] = newVal.ber_geburtstag.split("-");
        if (year && month && day) {
          jahr.value = parseInt(year);
          monat.value = parseInt(month);
          tag.value = parseInt(day);
        } else {
          console.error(
            "Invalid date format in ber_geburtstag:",
            newVal.ber_geburtstag
          );
        }
      } else {
        console.error("ber_geburtstag is undefined or not a string");
      }

      // Recalculate age based on the updated date
      calcAge();
    }
  },
  { immediate: true }
);

onMounted(() => {
  getRassen();
  proxy.$updateDocumentHead(
    "Hunde-Krankenversicherungen vergleichen",
    "Hunde-Krankenversicherungen vergleichen"
  );
});

// Updated watchers to only trigger after submission
watch(() => formRechner.plz, () => {
  if (submitted.value) checkFormFehler();
});
watch(() => dateChanged.value, () => {
  if (submitted.value) checkFormFehler();
});
watch(() => formRechner.hoehe, () => {
  if (submitted.value) checkFormFehler();
});
watch(() => formRechner.rasse, () => {
  if (submitted.value) checkFormFehler();
});
</script>