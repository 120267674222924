<template>
  <div class="pl-4 mt-4">
    <v-row >
      <v-col cols="12" md="9" xs="12">
        <v-row style="border-bottom: 4px solid #f2f2f2;" class="mb-4">
          <v-col cols="12" md="9" class="mt-4 pt-0">
            <h2 class="mb-0 pb-0">Hunde-Krankenversicherung</h2>
            <h1 class="mt-0 pt-0">
              <span class="redtext" style="font-size:36px;">{{
                tarif.tier_title
              }}</span>
            </h1>

           
            Die Hunde-Krankenversicherung {{ tarif.tier_title }} hat eine
            Durchschnittserstattung von
            <b class="redtext"> {{ tarif.tier_wert }}% </b>. Basis ist für die
            Ermittlung der Durchschnittserstattung ist die Eisbaum Realwert
            Methode (<a @click="rechnerStore.setShowPopTarif(item, 'rechenweg')"
              >Realwert Berechnung anzeigen</a
            >). <br />
            <div class="my-2" v-if="tarif.un_comment != ''">
              <b>Hinweis:</b> {{ tarif.un_comment }}
            </div>
            <v-row v-if="tarif.tier_vergleich_nein > 0" class="my-4">
              <v-col cols="12" md="12">
                <v-alert
                  border="top"
                  colored-border
                  type="info"
                  color="red"
                  elevation="2"
                >
                  <h2 v-if="tarif.tier_vergleich_status == 1">
                    Der Tarif wird gerade analysiert
                  </h2>
                  <h2 v-if="tarif.tier_vergleich_status == 2">
                    Versicherung arbeitet nicht mit Vergleichsportalen
                  </h2>
                  <h2 v-if="tarif.tier_vergleich_status == 3">
                    Tarif wurde von der Versicherung geschlossen
                  </h2>

                  Aus diesem Grund wurden die Tarifinformationen bisher noch
                  nicht von der Versicherung bestätigt bzw. Fragen zum Tarif von
                  der Versicherung beantwortet. Die Tarifinformationen basieren
                  lediglich auf unserer Auswertung der zugänglichen
                  Tarifunterlagen. Der Tarif wird nicht in unserem Online
                  Rechner berücksichtigt, weil wir keine Verantwortung für die
                  Vollständigkeit, Richtigkeit oder Aktualität der Informationen
                  übernehmen können.
                </v-alert>
              </v-col>
            </v-row>
            <!-- !{{ tarif.un_abschluss_url }} -->
            
            <v-btn
              v-if="tarif.un_abschluss_url != ''"
              class="nocaps my-4 "
              style="background-color: #d84315; color: #fff"
            >
              <a
                @click="saveAntragClickend()"
                :href="tarif.un_abschluss_url"
                target="_blank"
                class="text-white"
               
                >jetzt {{ tarif.tier_title }} online beantragen
              </a></v-btn
            >
          </v-col>
          <v-col cols="12" md="3">
            <div
              style="border-left:2px solid #d84315;background-color:#f2f2f2"
              class="pa-4"
            >
              <span class="redtext" style="font-size:64px;"
                >{{ tarif.tier_wert }}%
              </span>
              <br />Eisbaum Realwert
            </div>
          </v-col>
        </v-row>

    
        <!-- Tab Buttons -->
        <v-row>
          <v-col cols="12" md="12">
          <v-btn
            class="nocaps mr-4"
            :class="{ 'active-tab': activeTab === 'Leistungsbeschreibung' }"
            @click="activeTab = 'Leistungsbeschreibung'"
          >
            Leistungsbeschreibung
          </v-btn>
          <v-btn
            class="nocaps mr-4"
            :class="{ 'active-tab': activeTab === 'Eisbaum Testverfahren' }"
            @click="activeTab = 'Eisbaum Testverfahren'"
          >
            Eisbaum Testverfahren
          </v-btn>
          <v-btn
            class="nocaps mr-4"
            v-if="tarif.tier_vergleich_nein == 0"
            :class="{ 'active-tab': activeTab === 'Beitragstabelle' }"
            @click="activeTab = 'Beitragstabelle'"
          >
            Beitragstabelle
          </v-btn>
          <v-btn
            class="nocaps mr-4"
            v-if="beispiele && tarif.tier_vergleich_nein == 0"
            :class="{ 'active-tab': activeTab === 'Erstattungsbeispiele' }"
            @click="activeTab = 'Erstattungsbeispiele'"
          >
            Erstattungsbeispiele
          </v-btn>
          <v-btn
            class="nocaps mr-4"
            v-if="avb && tarif.tier_vergleich_nein == 0"
            :class="{ 'active-tab': activeTab === 'Downloads' }"
            @click="activeTab = 'Downloads'"
          >
            Downloads
          </v-btn>
        </v-col>
        </v-row>

        <!-- Content Sections -->
        <!-- Leistungsbeschreibung -->
        <v-row v-if="activeTab === 'Leistungsbeschreibung'" class="pt-4">
          <v-col cols="12" md="12">
         <v-row>
          <v-col cols="12" md="12" v-if="tarif.tier_sb_art == 3">
            <b>Dieser Tarif hat eine Selbstbeteiligung:</b><br />
            Die Selbstbeteiligung beträgt {{ tarif.tier_sb_prozent }} % des Rechnungsbetrages, maximal {{ tarif.tier_sb_euro }} € für alle zeitgleich zusammen eingereichten Rechnungen eines Abrechnungsjahres. Sparen Sie Geld, indem Sie die Rechnungen für Behandlungen, die in einem Abrechnungsjahr durchgeführt wurden, gesammelt in einem Vorgang zur Abrechnung einreichen. Achtung: Reichen Sie jede Rechnung einzeln ein, tragen Sie für jeden Rechnungsbetrag {{ tarif.tier_sb_prozent }} % Selbstbeteiligung.
          </v-col>
          </v-row>
       
          <div
           
            v-for="(item,index) in filteredOrigin"
            :key="item.value"
           
          >
            <v-row style="border-bottom:1px solid #f2f2f2">
              <v-col cols="12" md="3" xs="12">
                {{ item.title }}
              </v-col>
              <v-col cols="12" md="9" xs="12">
                <b>
                  <span class="pb-0 mb-0">{{ fieldStore.showFieldComputed(item.value, tarif,"hund") }}</span>
                </b>
                <a
                  v-if="item.spezialpop && item.spezialpop == 'rechenweg'"
                  @click="rechnerStore.setShowPopTarif(tarif, 'rechenweg')"
                >
                  &nbsp;mehr Infos... 
                </a>

               
              </v-col>
            </v-row>
            <v-row
              v-if="(index + 1) % 4 === 0"
              style="border-bottom:1px solid #f2f2f2"
            >
              <v-col cols="12" md="3" xs="12">
                
              </v-col>
              <v-col cols="12" md="9" xs="12">
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      v-if="tarif.un_abschluss_url != ''"
                      color="#d84315"
                      @click="saveAntragClickend()"
                      :href="`/redirectabschluss/${tarif.tier_id}`"
                      target="_blank"
                      class="text-white nocaps"
                    >
                      jetzt  online beantragen
                    </v-btn>
                  
                    <v-btn
            href="/rechner"
            class="nocaps ml-4 redtext"

        
           
            >Preis berechnen</v-btn
          >
                  </v-col>
                </v-row>
              
              </v-col>
            </v-row>
          </div>
          </v-col>
        </v-row>

        <!-- Eisbaum Testverfahren -->
        <v-row v-if="activeTab === 'Eisbaum Testverfahren'" class="pt-4">
          <IndexRechenweg :tierart="1" :tarif="tarif" />
        </v-row>

        <!-- Beitragstabelle -->
        <v-row class="pt-4"
          v-if="activeTab === 'Beitragstabelle' && tarif.tier_vergleich_nein == 0"
        >
          <p>
            Dies ist der Preis für die Krankenversicherung eines Cocker-Spaniels bei
            der {{ tarif.tier_title }}. Je jünger der Hund beim Versicherungsabschluss
            ist, desto günstiger ist der monatliche Beitrag.
          </p>
          <v-table>
              <thead>
                <tr>
                  <th>Alter</th>
                  <th>Preis</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in preise" :key="item.preis_id">
                  <td>{{ item.preis_alter }} Jahre</td>
                  <td>{{ item.preis_value_1 }} €</td>
                </tr>
              </tbody>
          </v-table>
        
        </v-row>

        <!-- Erstattungsbeispiele -->
        <v-row 
          v-if="
            activeTab === 'Erstattungsbeispiele' &&
            beispiele &&
            tarif.tier_vergleich_nein == 0
          "
          class="pt-4"
        >
          <v-row v-for="item in beispiele" :key="item.bei_id">
            <v-col cols="12" md="8">
              <b>{{ item.bei_title }}</b> <br />{{ item.bei_text }}
            </v-col>
            <v-col cols="12" md="2">
              <b>{{ item.bei_rechnung }} €</b>
              <br />
              <span class="caption">Tierarzt-Rechnung</span>
            </v-col>
            <v-col cols="12" md="2">
              <b class="text-green">{{ item.bei_erstattung }} €</b>
              <br />
              <span class="caption"
                >Erstattung durch {{ tarif.tier_title }}</span
              >
            </v-col>
          </v-row>
        </v-row>

        <!-- Downloads -->
        <v-row
          v-if="
            activeTab === 'Downloads' &&
            avb &&
            tarif.tier_vergleich_nein == 0
          "
          class="pt-4"
        >
          <ul>
            <li v-for="item in avb" :key="item.doc_id">
              <a :href="`/dist/tarif/${item.doc_dateiname}`" target="_blank">
                {{ item.doc_text }}
              </a>
            </li>
          </ul>
        </v-row>
      </v-col>
      <v-col cols="12" md="3" xs="12"
        >
        <RechnerBox showType="hund"/>

        <v-card outlined class="mt-8" v-if="tarif.un_abschluss_url != ''">
          <v-card-text
            ><h2>Online-Abschluss</h2>
            <p>
              Hier können Sie die <b>{{ tarif.tier_title }}</b> sofort online
              beantragen.
            </p>

            <v-btn
              class="nocaps mb-4"
              style="background-color: #d84315; color: #fff"
            >
              <a
                v-if="tarif.un_abschluss_url != ''"
                @click="saveAntragClickend()"
                :href="tarif.un_abschluss_url"
                target="_blank"
               class="text-white"
                >jetzt online beantragen
              </a></v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
 
  </div>
</template>

<script setup>
import { defineProps, ref, computed, toRefs } from 'vue';
import { useFieldStore } from '@/stores/fields';
import IndexRechenweg from '@/components/rechner/IndexRechenweg.vue';
import RechnerBox from '@/sites/base/RechnerBox.vue';
import { useBerechnungStore } from '@/stores/rechner';
const rechnerStore = useBerechnungStore();
import BaseCalls from "@/services/BaseCalls";

const fieldStore = useFieldStore();
const activeTab = ref('Leistungsbeschreibung');
const origin = ref(fieldStore.hund);

const props = defineProps({
  tarif: Object,
  testberichte: [Array, Boolean],
  preise: [Array, Boolean],
  avb: [Array, Boolean],
  benchmark: [Object, Boolean],
  beispiele: [Array, Boolean],
});

const {
  tarif,
  preise,
  avb,
  beispiele,
} = toRefs(props);

const filteredOrigin = computed(() => {
  return Object.values(origin.value).filter(item => {
    return !item.showintern && tarif.value[item.value];
  });
});

async function saveAntragClickend() {
  let select = {
    action: "saveclickantrag",
    tier_id: tarif.value.tier_id,
  }
  await BaseCalls.postTarif(select);
}
</script>

<style scoped>
.active-tab {
  background-color: #d84315 !important;
  color: #fff !important;
}
</style>