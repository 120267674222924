<template>
  <div>
    <div v-if="tarif.tier_id">
    <KatzeDesktop  v-if="!common.isMobile" :tarif="tarif" :testberichte="testberichte" :preise="preise" :avb="avb" :benchmark="benchmark" :beispiele="beispiele"/>
    <KatzeMobile  v-if="common.isMobile" :tarif="tarif" :testberichte="testberichte" :preise="preise" :avb="avb" :benchmark="benchmark" :beispiele="beispiele" />
  </div>
  </div>
</template>
<script setup>
import {ref,onMounted,getCurrentInstance } from "vue";
import BaseCalls from "@/services/BaseCalls";
import { useRoute } from "vue-router";
import { useLoaderStore } from "@/stores/loader";
import { useCommonDataStore } from "@/stores/common";
import KatzeDesktop from "@/sites/tarif/KatzeDesktop.vue";
import KatzeMobile from "@/sites/tarif/KatzeMobile.vue";
const common = useCommonDataStore();
const loaderStore = useLoaderStore();

const route = useRoute();
const { proxy } = getCurrentInstance();

const tarif = ref(false)
const testberichte = ref(false);
const beispiele = ref(false);
const preise = ref(false);
const avb = ref(false)
const benchmark = ref(false)

function setLoading(loading) {
  loaderStore.setLoader(loading);
  }
async function getTarif() {
    setLoading(true);
    let select = {
      action:  "getTarifeUrl",
      type: "einzel",
      id: route.params.id,
      number: "one",
    };
    const resp = await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.tarife) {
        tarif.value = resp.data.tarife;
        getAvb(tarif.value.tier_id);
        getPreise(tarif.value.tier_id);
        getTestberichte(tarif.value.tier_id);
        getTarifBenchmark(tarif.value.tier_id);
        proxy.$updateDocumentHead(
         tarif.value.tier_title +
            " Krankenversicherung für Katze " +
            tarif.value.tier_wert +
            " % von 100 % im Eisbaum-Test (5-Jahres Realwert-Methode)",
      tarif.value.tier_title +
            " Krankenversicherung Katze " +
            tarif.value.tier_wert +
            " % von 100 % im Eisbaum-Test"
    );
      }
      setLoading(false);
  }



async function getAvb(id) {
    let select = {
      action:  "getAvB",
      id: id,
    };
    const resp = await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.avb) {
        avb.value = resp.data.avb;
      }
  }



async function getPreise(id) {
    let select = {
      action:  "getPreise",
      type: "alle",
      number: "array",
      id: id,
    };
    const resp = await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.preise) {
        preise.value = resp.data.preise;
      }
  }


async function getTestberichte(id) {
    let select = {
      action:  "getTestberichte",
      id: id,
    };
    const resp = await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.testberichte) {
        testberichte.value = resp.data.testberichte;
      }
  }

  async function getTarifBenchmark(id) {
    let select = {
      action:  "getTarifBenchmark",
      id: id,
    };
    const resp = await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.benchmark) {
        benchmark.value = resp.data.benchmark;
      }
  }




onMounted(() => {
    getTarif();
   
  });
</script>