import { defineStore } from "pinia";
import { ref, computed } from "vue"; // Import computed from Vue

export const useCommonDataStore = defineStore("commonData", () => {
  const windowWidth = ref(
    typeof window !== "undefined" ? window.innerWidth : 0
  );

  // Update windowWidth when the window is resized
  if (typeof window !== 'undefined') {
    window.addEventListener('resize', () => {
      windowWidth.value = window.innerWidth;
    });
  }

  // Define computed properties
  const isMobile = computed(() => windowWidth.value < 780);
  const isIpadAndBelow = computed(() => windowWidth.value < 1450);
  const showMenueRechts = computed(() => windowWidth.value >= 1450);
  const showMenueLeft = computed(() => windowWidth.value >= 1150);
  const isDesktop = computed(() => windowWidth.value >= 770);

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      console.log(`Element mit der ID ${elementId} wurde nicht gefunden.`);
    }
  };

  return {
    showMenueLeft,
    isMobile,
    isIpadAndBelow,
    isDesktop,
    scrollToElement,
    showMenueRechts,
  };
});
