<template>
<div v-if="user" >
    <v-row class="pl-4 pa-8">
      <v-col cols="12" md="9">
       
        <h2>Ihr Tierarzt-Account: {{user.user_name }}</h2>

        <v-row class="mt-2">
          <v-col cols="12" md="2">Strasse</v-col>
          <v-col cols="12" md="6"
            >{{user.user_strasse }} {{user.user_hsnr }}</v-col
          >
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12" md="2">Ort</v-col>
          <v-col cols="12" md="6"
            >{{user.user_plz }} {{user.user_ort }}
            <br><router-link to="stammdaten">Adresse ändern</router-link>
            </v-col
          >
        </v-row>
      <v-row class="mt-12" v-if="successBestellung">
          <v-col cols="12" md="9">
            <h2>
             Wir haben Ihre Bestellung erhalten.
              <sup class="redtext">es hat geklappt</sup>
            </h2>
            <p>
              Wir versenden Ihre EisbaumTabellen in 1-2 Werktagen. Dieser Service ist für Sie kostenlos.
            </p>
           
          </v-col>
        </v-row>
        <v-row class="mt-12" v-if="!successBestellung">
          <v-col cols="12" md="9">
            <v-row>
              <v-col cols="12" md="12">
         
            <h2>
              Kostenlos neue EisbaumTabellen bestellen
              <sup class="redtext">kostenlos</sup>
            </h2>
            <p>
              Bestellen Sie jetzt kostenlos neue EisbaumTabellen der besten
              <ul>
                <li>Hunde-Krankenversicherungen </li>
                <li>Katzen-Krankenversicherungen </li>
              </ul>
              für Ihr Wartezimmer. Wir schicken
              Ihnen diese innerhalb von 2-3 Werktagen kostenlos zu.
            </p>
          </v-col>
            </v-row>
            <v-row>
          <v-col cols="12" md="1" class="my-0 py-4">
            <v-checkbox
              v-model="togoChecked"
              class="ma-0"
              @click="addOderEntferneProdukt('EisbaumTabelle ToGo', 'tabelle')"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="8">
            <strong style="color: #2196f3"
              >EisbaumTabelle ToGo | Hund & Katze
              <sup class="text-green">Tipp</sup></strong
            >
            <b> | zur Mitnahme</b> <br />T0P 10 Tarife Hund & Katzes - im
            praktischen Postkartenformat. Zur Mitnahme. Ideal in Kombination mit
            WaizmannTabelle Acrylglas-Aufsteller.
          </v-col>
          <v-col cols="12" md="3">
            <img
              :src="require('@/assets/bestellung/togo.png')"
              style="width: 60px; float: left"
            />
          </v-col>
          <v-row class="my-2">
          <v-col cols="12" md="1" class="my-0 py-4">
            <v-checkbox
              class="ma-0"
              @click="addOderEntferneProdukt('Aufsteller ', 'tabelle')"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="8">
            <strong style="color: #2196f3"
              >Transparenter Acrylglas-Aufsteller für ToGo Tabellen
            </strong>
            <br />Damit können Sie die ToGo Tabellen in Ihrer Praxis zur
            Mitnahme anbieten. Wenn Sie mehrere Aufsteller (kostenlos)
            benötigen, fordern Sie diesen einfach mehrfach an.
          </v-col>
          <v-col cols="12" md="3">
            <img
              :src="require('@/assets/bestellung/aufsteller.png')"
              style="width: 60px; float: left"
            />
          </v-col>
        </v-row>
        </v-row>
            <v-btn
              type="submit"
              @click="insertBestellung()"
              tile
              :disabled="showButton"
              outlined
              color="#d84315"
              class="nocaps"
              >Jetzt kostenlos neue EisbaumTabellen für Ihre Praxis
              bestellen</v-btn
            >
          </v-col>
        </v-row>

        <v-row class="mt-4" v-if="bestellungen.length > 0">
          <v-col cols="12" md="9">
            <h2>Ihre "Tabellen-Bestellungen"</h2>
            <ul>
              <li v-for="item in bestellungen" :key="item.bestell_id">
            {{ item.bestell_produkt_text }} bestellt am <b>{{ item.bestell_created_date.substr(0,10) }}</b>
  | <b v-if="item.bestell_status == 0" class="orange--text">wird gerade versendet</b>
 <b v-if="item.bestell_status > 0" class="text-green">wird wurde versendet</b>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="3">
        <MenueDownload/>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, watch } from "vue";
import MenueDownload from "@/sites/base/MenueDownload.vue";
import BaseCalls from "@/services/BaseCalls";
import { useUserStore } from "@/stores/user";
import { useLoaderStore } from "@/stores/loader";

const loaderStore = useLoaderStore();
function setLoading(loading) {
  loaderStore.setLoader(loading);
}

const { proxy } = getCurrentInstance();
const userStore = useUserStore();
const user = ref(false);
const showButton = ref(true); // Initialize as true
const bestellungen = ref([]);
const successBestellung = ref(false);
const newBestellung = ref({
  bestell_type: 1,
  bestell_produkt_text: ''
});


// Watcher to update user.value when userStore.user changes
watch(
  () => userStore.user,
  (newUser) => {
    user.value = newUser;
  },
  { immediate: true } // R
);

async function getBestellungen() {
  setLoading(true);
  let select = {
    action: "getBestellungen"
  };

  const resp = await BaseCalls.postTarif(select);
  if (resp.data && resp.data.bestellungen) {
    bestellungen.value = resp.data.bestellungen;
    setLoading(false);
  } else {
    setLoading(false);
  }
 
}

function addOderEntferneProdukt(titel) {
  newBestellung.value.bestell_produkt_text = newBestellung.value.bestell_produkt_text || '';

  let produkte = newBestellung.value.bestell_produkt_text.split(", ").filter(Boolean);
  let index = produkte.indexOf(titel);

  if (index !== -1) {
    // Remove the title if it already exists
    produkte.splice(index, 1);
  } else {
    // Add the title if it doesn't exist
    produkte.push(titel);
  }

  newBestellung.value.bestell_produkt_text = produkte.join(", ");
}

async function insertBestellung() {
  if (!user.value) {
    return;
  }
  setLoading(true);
  newBestellung.value.user_id = user.value.user_id;
  newBestellung.value.action = "insertbestellung";
  const resp = await BaseCalls.postTarif(newBestellung.value);
  if (resp.data) {
    bestellungen.value = resp.data.bestellungen;
    successBestellung.value = true;
    setLoading(false);
    getBestellungen();
  }
}

// Watch for changes in newBestellung.bestell_produkt_text
watch(
  () => newBestellung.value.bestell_produkt_text,
  (newValue) => {
    if (newValue !== '') {
      showButton.value = false;
    } else {
      showButton.value = true;
    }
  }
);

onMounted(() => {
  getBestellungen();
  proxy.$updateDocumentHead(
    "Kostenlos neue EisbaumTabellen für Ihre Praxis anfordern ",
    "Kostenlos neue EisbaumTabellen für Ihre Praxis anfordern "
  );
});
</script>