<template>
  <div>
    <v-card class="mb-8" elevation="0" style="background-color: #f2f2f2">
      <v-container v-if="user">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="text-green">Sie sind jetzt erfolgreich eingeloggt</h2>
          </v-col>
        </v-row>
      </v-container>
      <v-alert v-if="passSuccess" type="success" class="text-white"
        >Wir senden Ihnen an die E-Mail ein neues Passwort zu.</v-alert
      >
 
      <v-container v-if="!user && !passVergessen">
        <h2 class="redtext mb-4">Tierarzt-Login EisbaumTabelle.de</h2>
        <v-row dense>
          <v-col cols="12" md="10">
            <v-text-field v-model="select.email" label="E-Mail"></v-text-field>
            <v-text-field
              v-model="select.checkcheck"
              :type="showPassword ? 'text' : 'password'"
              label="Passwort"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="10" class="pt-4"
            ><v-btn
              color="#d84315"
              @click="startLogin()"
              class="nocaps"
              outlined
              tile
              >jetzt einloggen und EisbaumTabelle anfordern</v-btn
            >
            <v-checkbox
              v-model="passVergessen"
              label="Ich habe meine Passwort
            vergessen"
            />

            <div class="pa-2 mt-2"
                  style="background-color: #d84315;"  v-if="error">
           <h2 class="text-white">Ihre E-Mail oder das Passwort scheint falsch zu sein</h2>
          </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="!user && passVergessen">
        <h2>Neues Eisbaum-Passwort anfordern</h2>
        Bitte geben Sie Ihre E-Mail an die wir Ihr neues Passwort senden sollen.

        <v-text-field
          v-model="select.checkcheck"
          label="Ihre E-Mail"
        ></v-text-field>
        <v-btn
          v-if="select.checkcheck != ''"
         color="#d84315"
          @click="startPassVergessen()"
          class="nocaps"
          outlined
          tile
          >Jetzt hier klicken und ein neues Passwort anfordern</v-btn
        >
      </v-container>
    </v-card>
  </div>
</template>

<script setup>
import { ref } from "vue";
import BaseCalls from "@/services/BaseCalls";
import { useUserStore } from "@/stores/user";
import { useLoaderStore } from "@/stores/loader";

const userStore = useUserStore();
const loaderStore = useLoaderStore();

const showPassword = ref(false); // Add this line
const passVergessen = ref(false);
const passSuccess = ref(false);
const error = ref(false);
const select = ref({
  email: "",
  checkcheck: "",
});

const user = ref(false);

function setLoading(loading) {
  loaderStore.setLoader(loading);
}

async function startPassVergessen() {
  setLoading(true);
  let select = {
    
  };
  select.value.action = "passforgot";
  const resp = await BaseCalls.postUser(select.value); // Korrigiert, um .value zu verwenden
  if (resp.data.success) {
            passSuccess.value = true;
            passVergessen.value = false;
            error.value = false;
          } else {
            error.value = true;
          }
  setLoading(false);
}

async function startLogin() {
  setLoading(true);
  error.value = false;
  select.value.action = "startlogin";
  const resp = await BaseCalls.postUser(select.value); // Korrigiert, um .value zu verwenden
  if (resp.data.user) {
            userStore.setUser(resp.data.user);
            user.value = resp.data.user;
            window.location.href = "/account";
            // this.$router.push("/account");
          } else {
            error.value = true;
          }
  setLoading(false);
}




</script>
