<template>
  <v-container>
    <v-row class="my-8">
      <v-col>
        <h1>Tierkrankenversicherungen für die Zahnsteinentfernung bei Hunden</h1>

        <ul class="my-4 ml-8">
          <li>Welche Tierkrankenversicherungen leisten für die Zahnsteinentfernung bei Tieren?</li>
          <li>Wie viel kostet die Zahnsteinentfernung beim Hund unter Narkose?</li>
          <li>Unsere Testsieger leisten bis zu 1.100 € für die Zahnsteinentfernung beim Hund</li>
        </ul>

        <h3>Diese Tierkrankenversicherungen leisten für Zahnsteinentfernung</h3>

     
        <v-table >
                <br />
                <thead>
                
                  <tr style="background-color:#f2f2f2">
                    <th class="py-2">Testsieger</th>
                    <th class="py-2">Ø-Erstattung</th>

                    <th class="py-2">
                      Zahnsteinentfernung
                    </th>
                  
                    <th class="py-2" v-if="!common.isMobile">mtl. Beitrag</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style="vertical-align: top"
                    v-for="item in hunde"
                    :key="item.tier_id"
                  >
                    <td>
                      <a
                        ><router-link
                          :to="`/Krankenversicherung_Hund/${item.tier_url}`"
                          >{{ item.tier_title }}</router-link
                        >
                      </a>
                    </td>
                    <td>
                      <a  @click="rechnerStore.setShowPopTarif(item, 'rechenweg')"
                        ><b>{{ item.tier_wert }} %</b></a
                      >

              
                    </td>

                    <td>
                      <b>{{ item.tier_op_zahnstein }}</b
                      >
                    </td>
                    <td style="min-width:100px;" v-if="!common.isMobile">
                      <span v-if="item.tier_preis_est == 0">
                        <b>{{ item.preis }} €</b>
                      </span>
                      <span v-if="item.tier_preis_est == 1">
                        ca. {{ item.preis }} €
                        <a
                       @click="rechnerStore.setShowPopTarif(item, 'preis_est')"
                          class="caption"
                          ><br />Wieso ca.?</a
                        >
                      
                      </span>
                    </td>
                  </tr>
               
           
                </tbody></v-table
              >
        <!-- Erweiterte Tabelle wird bei Klick angezeigt -->
    
        <h3>Warum ist die Zahnsteinentfernung beim Hund so wichtig?</h3>
        <div>
          Die Zahnsteinentfernung bei Hunden ist deshalb so wichtig, weil schlechte Zähne schwerwiegende Folgeerkrankungen an lebenswichtigen Organen verursachen können. Die Zahnsteinentfernung beim Hund verhindert nicht nur Maulgeruch, sondern beugt auch ernsthaften Gesundheitsproblemen vor. Zahnstein bietet Bakterien eine ideale Basis, um sich auszubreiten, was zu Zahnfleischentzündungen, Zahnverlust und sogar zu schweren Erkrankungen lebenswichtiger Organe wie Herz, Leber und Nieren führen kann.
          <br><br>
          Regelmäßige Zahnkontrollen durch den Tierarzt sind entscheidend, um Zahnstein rechtzeitig zu entfernen und so die Kosten für Folgeerkrankungen zu minimieren. Tierkrankenversicherungen übernehmen die Kosten für regelmäßige Vorsorgeuntersuchungen beim Tierarzt.
        </div>

        <v-btn
          href="https://www.eisbaumtabelle.de/rechner"
          target="_blank"
          color="#d84315"
          class="my-4 nocaps text-white"
        >
          Jetzt die leistungsstärksten Krankenversicherungen für Hunde vergleichen
        </v-btn>

        <h3>Wie viel kostet eine Zahnsteinentfernung bei Hunden?</h3>
        <div>
          Eine Zahnsteinentfernung beim Hund wird in der Regel immer unter Narkose durchgeführt. Wenn die Zähne stark beschädigt sind, kann es vorkommen, dass ein Zahn gezogen werden muss. Je nach Aufwand der Behandlung können die Kosten zwischen 350 € (1-facher Satz GOT) und 1.000 € (3-facher Satz GOT) liegen.
          <br><br>
          <h2>Rechnungsbeispiel: Zahnsteinentfernung unter Narkose plus Entfernung des vierten Prämolar zum 2-fachen GOT Faktor:</h2>
        </div>

        <!-- Hier fügen wir die hartcodierte v-table ein -->
        <v-table class="my-4">
          <thead>
            <tr style="background-color:#f2f2f2">
              <th>GOT-Position / Tierärztliche Leistung</th>
              <th>1-facher Gebührensatz</th>
              <th>2-facher Gebührensatz</th>
              <th>3-facher Gebührensatz</th>
            </tr>
          </thead>
          <tbody>
            <!-- Abschnitt: Teil A - Grundleistungen -->
            <tr>
              <td colspan="4"><strong>Teil A: Grundleistungen</strong></td>
            </tr>
            <tr>
              <td>16 Allgemeine Untersuchung mit Beratung, Hund, Katze, Frettchen</td>
              <td>23,62 €</td>
              <td>47,24 €</td>
              <td>70,86 €</td>
            </tr>
            <tr>
              <td>643 Eingehende Untersuchung einzelner Organe; Herz</td>
              <td>15,39 €</td>
              <td>30,78 €</td>
              <td>46,17 €</td>
            </tr>
            <tr>
              <td>921 Eingehende Untersuchung einzelner Organe des stomatognathen Systems</td>
              <td>16,43 €</td>
              <td>32,86 €</td>
              <td>49,29 €</td>
            </tr>
            <!-- Abschnitt: Teil B - Besondere Leistungen -->
            <tr>
              <td colspan="4"><strong>Teil B: Besondere Leistungen</strong></td>
            </tr>
            <tr>
              <td>225 Venenkatheter peripher einlegen</td>
              <td>14,62 €</td>
              <td>29,24 €</td>
              <td>43,86 €</td>
            </tr>
            <!-- Abschnitt: Teil C - Organsysteme -->
            <tr>
              <td colspan="4"><strong>Teil C: Organsysteme</strong></td>
            </tr>
            <tr>
              <td>320 Injektionsnarkose intravenös</td>
              <td>24,19 €</td>
              <td>48,38 €</td>
              <td>72,57 €</td>
            </tr>
            <tr>
              <td>344 Intubation, endotracheale</td>
              <td>24,00 €</td>
              <td>48,00 €</td>
              <td>72,00 €</td>
            </tr>
            <tr>
              <td>933 Entfernung von Zahnstein und Belägen mit Scaling und Politur, kompliziert</td>
              <td>108,82 €</td>
              <td>217,64 €</td>
              <td>326,46 €</td>
            </tr>
            <tr>
              <td>955 Zahnextraktion (z.B.: vierten Prämolar (P4)), kompliziert</td>
              <td>41,04 €</td>
              <td>82,08 €</td>
              <td>123,12 €</td>
            </tr>
            <!-- Gesamt netto -->
            <tr>
              <td><strong>Gesamt netto</strong></td>
              <td><strong>268,11 €</strong></td>
              <td><strong>536,22 €</strong></td>
              <td><strong>804,33 €</strong></td>
            </tr>
            <!-- Gesamt brutto -->
            <tr>
              <td><strong>Gesamt brutto</strong></td>
              <td><strong>319,05 €</strong></td>
              <td><strong>638,10 €</strong></td>
              <td><strong>957,15 €</strong></td>
            </tr>
            <!-- Hinweis -->
            <tr>
              <td colspan="4">
                Hinzukommen das Entgelt für Arzneimittel + Verbrauchsmaterialien (z.B. Handschuhe, Maske; Venenkatheter, Infusionsbesteck, OP-Verbrauchsmaterial)
              </td>
            </tr>
          </tbody>
        </v-table>

        <div>
          Regelmäßige Vorsorgeuntersuchungen beim Tierarzt sind entscheidend, um kostspielige Zahnsanierungen zu vermeiden.
          <br><br>
          Schon im Welpenalter sollten Sie Ihr Tier an die regelmäßige Zahnpflege gewöhnen. Durch frühzeitiges und regelmäßiges Zähneputzen lassen sich weiche Zahnbeläge einfach entfernen, wodurch die Entstehung von Zahnstein verzögert oder sogar verhindert werden kann. Außerdem ist es ratsam, bei der jährlichen Vorsorgeuntersuchung beim Tierarztbesuch auch die Zahngesundheit Ihres Hundes anzusprechen. Ihr Tierarzt kann Ihnen zusätzliche Tipps zur Zahnpflege geben und den Zahnstatus Ihres Tieres überprüfen. Über die entsprechenden Vorsorgemaßnahmen können Sie kostspielige Zahnsanierungen wie diese vermeiden.
          <a href="/images/Erstattungsbeispiel.pdf" target="_blank">realen Kostenvoranschlag anzeigen</a>
        </div>

        <v-btn
          href="https://www.eisbaumtabelle.de/rechner"
          target="_blank"
          color="#d84315"
          class="my-4 nocaps text-white"
        >
          Vergleichen Sie jetzt die besten Tierkrankenversicherungen mit Vorsorgepauschale
        </v-btn>

        <!-- Weitere Abschnitte analog fortsetzen -->

        <!-- Beispiel für die 4 Phasen -->
       <!-- Timeline Darstellung der 4 Phasen -->
    <div class="mt-6 py-6">
<h2 class="mt-10">4 Phasen | So läuft die Zahnsteinentfernung bei Hunden ab</h2>
<v-timeline align-top >
  <v-timeline-item
    color="primary"
    icon="mdi-numeric-1-circle"
  >
    <v-card class="pa-4">
     <h3>Phase 1</h3>
      <v-card-text>
        Zunächst wird der Hund sediert und intubiert, um eine sichere und stressfreie Behandlung zu gewährleisten. Die Narkose wird dabei kontinuierlich überwacht, um sicherzustellen, dass das Tier während des gesamten Eingriffs stabil bleibt. Der Tierarzt beginnt dann mit der Erhebung des Gebissstatus, wobei sowohl der Zustand der Zähne als auch des Zahnfleisches (Parodontalstatus) gründlich überprüft werden.
      </v-card-text>
    </v-card>
  </v-timeline-item>

  <v-timeline-item
    color="primary"
    icon="mdi-numeric-2-circle"
  >
    <v-card class="pa-4">
     <h3>Phase 2</h3>
      <v-card-text>
        Im nächsten Schritt erfolgt die eigentliche Zahnsteinentfernung. Zunächst wird der grobe Zahnstein manuell mit speziellen Instrumenten wie Scalern, Zahnsteinzangen oder Küretten entfernt. Anschließend kommen Ultraschallgeräte zum Einsatz, um die restlichen Ablagerungen auf den Zahnkronen gründlich zu beseitigen.
      </v-card-text>
    </v-card>
  </v-timeline-item>

  <v-timeline-item
    color="primary"
    icon="mdi-numeric-3-circle"
  >
    <v-card class="pa-4">
     <h3>Phase 3</h3>
      <v-card-text>
        Nach der Zahnsteinentfernung wird die Zahnoberfläche poliert, um die durch den Eingriff entstandenen Mikroschäden am Zahnschmelz zu glätten. Diese Politur hilft, eine schnellere Neubildung von Zahnstein zu verhindern.
      </v-card-text>
    </v-card>
  </v-timeline-item>

  <v-timeline-item
    color="primary"
    icon="mdi-numeric-4-circle"
  >
    <v-card class="pa-4">
      <h3>Phase 4</h3>
      <v-card-text>
        Zum Abschluss informiert der Tierarzt den Tierhalter über Maßnahmen zur Verbesserung der Maulhygiene. Dabei werden Tipps zur regelmäßigen Zahnpflege gegeben, um die zukünftige Zahnsteinbildung zu minimieren und die Zahngesundheit des Hundes langfristig zu erhalten.
      </v-card-text>
    </v-card>
  </v-timeline-item>
</v-timeline>
</div>

<v-row class="mt-4">
  <v-col cols="12">
<h3>3 Maßnahmen um kostspielige Zahnsteinentfernungen bei Hunden zu vermeiden</h3>
<ol class="ml-4">
  <li><b>Putzen Sie Ihrem Hund die Zähne!</b><br>Die beste Vorsorge für die Zahngesundheit Ihres Hundes ist eine regelmäßige und gründliche Zahnpflege. Gewöhnen Sie Ihren Hund bereits im Welpenalter spielerisch an das tägliche Zähne putzen. Dadurch wird Zahnbelag täglich entfernt und der Ansatz von Zahnstein verzögert oder sogar verhindert. 
  </li>
  <li><b>Die richtige Fütterung</b><br>Ihr Hund sollte idealerweise nur zweimal täglich gefüttert werden, damit das Gebiss ausreichend Zeit hat, sich von selbst zu reinigen. Trockenfutter ist dabei besonders vorteilhaft, da das Kauen einen leichten Abrieb erzeugt, der die Zahnsteinbildung verlangsamt. Ergänzend können Kauknochen die Zahnpflege unterstützen.</li>
  <li><b>Regelmäßige Vorsorgeuntersuchungen beim Tierarzt</b><br>Zudem sollten Sie regelmäßige Vorsorgeuntersuchungen beim Tierarzt wahrnehmen. Der Tierarzt erkennt Zahnbeläge frühzeitig und kann diese einfach entfernen. So vermeiden Sie kostspielige und für Ihren Hund anstrengende Zahnsteinentfernungen unter Narkose.</li>
</ol>

<a href="https://www.eisbaumtabelle.de/rechner" target="_blank">Jetzt Tierkrankenversicherung finden die umfassend für Vorsorgeuntersuchungen leistet</a>

</v-col>
</v-row>
<v-row class="mt-4">
  <v-col cols="12">
<h2>Zahnsteinentfernung bei Katzen | Wichtige Unterschiede im Überblick</h2>
Auch bei Katzen ist Zahnstein ein häufiges Problem, insbesondere bei älteren Tieren und Hauskatzen. Daher sollten auch bei Katzen regelmäßige Vorsorgeuntersuchungen beim Tierarzt erfolgen, bei denen besonders auf die Zahngesundheit geachtet wird. Der Ablauf der Zahnsteinentfernung ähnelt dem beim Hund, auch hier ist eine Narkose erforderlich. Allerdings sind Katzen oft empfindlicher, und die Behandlung kann etwas komplizierter sein. 
Auch die Kosten für die Zahnsteinentfernung bei Katzen sind ähnlich wie bei Hunden.
Sie können je nach Umfang der Behandlung zwischen 300 € und 900 € liegen.
<br><br>
<h3>Diese Tierkrankenversicherungen leisten für die Zahnsteinentfernung bei Katzen</h3>

<v-table >
                <br />
                <thead>
                
                  <tr style="background-color:#f2f2f2">
                    <th class="py-2">Testsieger</th>
                    <th class="py-2">Ø-Erstattung</th>

                    <th class="py-2">
                      Zahnsteinentfernung
                    </th>
                  
                    <th class="py-2" v-if="!common.isMobile">mtl. Beitrag</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style="vertical-align: top"
                    v-for="item in katzen"
                    :key="item.tier_id"
                  >
                    <td>
                      <a
                        ><router-link
                          :to="`/Krankenversicherung_Katze/${item.tier_url}`"
                          >{{ item.tier_title }}</router-link
                        >
                      </a>
                    </td>
                    <td>
                      <a  @click="rechnerStore.setShowPopTarif(item, 'rechenweg')"
                        ><b>{{ item.tier_wert }} %</b></a
                      >

              
                    </td>

                    <td>
                      <b>{{ item.tier_op_zahnstein }}</b
                      >
                    </td>
                    <td style="min-width:100px;" v-if="!common.isMobile">
                      <span v-if="item.tier_preis_est == 0">
                        <b>{{ item.preis }} €</b>
                      </span>
                      <span v-if="item.tier_preis_est == 1">
                        ca. {{ item.preis }} €
                        <a
                       @click="rechnerStore.setShowPopTarif(item, 'preis_est')"
                          class="caption"
                          ><br />Wieso ca.?</a
                        >
                      
                      </span>
                    </td>
                  </tr>
               
           
                </tbody></v-table
              >

<a href="https://www.eisbaumtabelle.de/vergleich_katze" target="_blank">Jetzt die Leistungsstärksten Krankenversicherungen für Katzen vergleichen</a>

</v-col>
</v-row>
<v-row>
  <v-col cols="12">
<h2 class="mt-10">Diese 6 Fragen werden uns immer wieder von Tierbesitzern gestellt:</h2>

<h3>1. Wie oft muss Zahnstein beim Hund entfernt werden?</h3>
Wie oft eine Zahnsteinentfernung bei einem Hund notwendig ist, lässt sich nicht pauschal sagen, da dies von verschiedenen Faktoren abhängt. Neben der regelmäßigen Zahnpflege durch den Tierhalter spielen auch die Fütterung und die Rasse des Hundes eine Rolle. Kleinere Rassen neigen beispielsweise eher zu Zahnstein als größere. Als Tierhalter sollte man bei der Zahnpflege besonders auf Verfärbungen achten und bei Bedarf frühzeitig den Tierarzt aufsuchen.
<br /><br />
<h3>2. Kann die Zahnsteinentfernung wirklich nur unter Narkose durchgeführt werden und leistet die Tierkrankenversicherung dafür?</h3>
Wenn ein Tierbesitzer bei seinem Hund Zahnstein bemerkt, ist dieser meist schon stark ausgeprägt und kann nur noch durch eine Zahnsteinentfernung beim Tierarzt entfernt werden. Dafür ist eine Narkose erforderlich, da nur so eine gründliche Untersuchung der Maulhöhle und eine sorgfältige Entfernung des Zahnsteins möglich ist.
Die Narkose wird versicherungstechnisch der Behandlung oder Operation zugeordnet, für die sie durchgeführt wird. Leistet die Tierkrankenversicherung also für die Zahnsteinentfernung des Tieres, leistet Sie (vorbehaltlich der Leistungsbegrenzungen) auch für eine damit in Zusammenhang stehende Narkose.
<br /><br />
<h3>3. Was kostet die Narkose?</h3>
Die Kosten der Narkose hängen von der Art der Narkose, der Größe des Tieres und von dem notwendigen Aufwand ab. Für die Zahnsteinentfernung wird in der Regel die intra­venös Injek­ti­ons­nar­kose verwendet. Die GOT Position 320 Injektionsnarkose intravenös kann vom Tierarzt mit bis zu 72,57 € (3-facher Satz) angesetzt werden. Ist eine Narkoseüberwachung per Monitor notwendig (349 Monitoring mit bis zu zwei Parametern) können weitere 158,88 € dazu kommen. Zusätzlich wird OP-Verbrauchsmaterial abgerechnet und die Mehrwertsteuer kommt hinzu. Das heißt bei komplexen Eingriffen können sich die Kosten für eine Narkose auf ca. 300 € belaufen.
<br /><br />
<h3>4. Ist Dentalröntgen für eine Zahnsteinentfernung notwendig?</h3>
Dentalröntgen (intraorales Dentalröntgen) ist eine spezielle Röntgentechnik, bei der über einen Röntgenfilm in der Maulhöhle des Tieres die Zähne, die Zahnwurzeln und der Kiefer vollständig abgebildet werden können. Diese Methode wird vor allem bei umfangreichen Zahnsanierungen eingesetzt, da sie dem Tierarzt ermöglicht, Fehlstellungen, FORL / CORL, Zahnfrakturen, Wurzelprobleme, Zahn- und Kieferanomalien (persistierende Milchzähne) zuverlässig zu erkennen und zu behandeln. Die Kosten für das Dental-Röntgen aller 4 Kiefer-Quadranten kann sich auf ca. 220 € belaufen. 
<br /><br />
<h3>5. Welche Tierkrankenversicherung übernimmt die Kosten für persistierende Milchcanini?</h3>
Persistierende Milchcanini (nicht ausfallende Milch-Eckzähne) gehören zu den häufigsten Zahnerkrankungen bei jungen Hunden. Dabei verbleiben die Milch-Eckzähne im Kiefer, obwohl die bleibenden Eckzähne bereits durchbrechen. Das passiert meist, weil der bleibende Zahn nicht direkt unter dem Milchzahn angelegt ist und der Milchzahn dadurch beim Zahnwechsel nicht gelockert wird. Dies kann zu Fehlstellungen, Zahnfleischerkrankungen und Zahnschäden führen. Um solche Folgeerkrankungen zu vermeiden, müssen persistierende Milchcanini vom Tierarzt vollständig entfernt werden, damit keine Zahnrückstände das gesunde Zahnwachstum beeinträchtigen.
<br /><br />
Persistierende Milchcanini wird versicherungstechnisch als Korrektur von Zahn- und Kieferanomalien betrachtet. Hierfür leisten die meisten Tierkrankenversicherungen nicht. Nur die Tarife der Barmenia Tierkrankenversicherungen leisten bis zu 500 € pro Jahr für Zahn- und Kieferanomalien.
<a href="https://www.eisbaumtabelle.de/Krankenversicherung_Hund/Barmenia_Tierkranken_Premium" target="_blank">Barmenia Tierkranken Premium</a>
<br /><br />
<b>Hinweis:</b> Ist die Zahnerkrankung bereits vor Abschluss der Versicherung bekannt, kann sie nicht mehr in den Versicherungsschutz aufgenommen werden und es erfolgt keine Leistung dafür.
<br /><br />
<h3>6. Welche Tierkrankenversicherung leistet für FORL?</h3>
FORL (Feline Odontoklastische Resorptive Läsionen) ist eine bei Katzen besonders weit verbreitete und sehr schmerzhafte Zahnerkrankung, bei der körpereigene Zellen den Zahn und seine Wurzel auflösen. Die häufigsten Symptome sind starke Zahnschmerzen, vermehrter Speichelfluss, Futterverweigerung, Zähneknirschen und Zahnverlust. 
Da der Zerfall der Zahnsubstanz nicht aufgehalten oder rückgängig gemacht werden kann, werden betroffene Zähne in der Regel durch einen chirurgischen Eingriff entfernt. Es muss darauf geachtet werden, dass die Zähne komplett entfernt werden, denn Zahn-Rückstände können im Kiefer zu Entzündungen führen. 
Um diese Krankheit möglichst früh zu erkennen, sollten Sie regelmäßige Vorsorgeuntersuchungen inklusive Dental Röntgen mit Ihrer Katze wahrnehmen.  
Auch bei Hunden kann diese Autoimmunerkrankung auftreten, wird dort jedoch als CORL bezeichnet und kommt wesentlich seltener vor.
<br /><br />
Die Tarife von Lassie leisten mit einer Wartezeit von 18 Monaten ab Versicherungsbeginn für FORL. 
<a href="https://www.eisbaumtabelle.de/Krankenversicherung_Katze/Lassie_Maxi" target="_blank">Lassie Maxi</a>
<br /><br />
Die Tarife der DA Direkt leisten einmalig 1.500 € im Rahmen der Leistung für Fehlentwicklungen, wenn die klinisch relevanten Symptome oder die Diagnosestellung nach dem Versicherungsbeginn liegt. Allerdings werden die Leistungen auf die Leistungsbegrenzungen in den in den ersten zwei Jahren angerechnet.
<br /><br />
<a href="https://www.eisbaumtabelle.de/Krankenversicherung_Katze/DADirekt_Tierkrankenversicherung_Premium_Plus" target="_blank">DA Direkt Premium Plus</a>
<br /><br />
Hinweis: Tritt die Diagnose oder deren Symptome in der Wartezeit auf, gilt die Erkrankung als Vorerkrankung und die Versicherungen leisten nicht dafür.
</v-col>
</v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import BaseCalls from "@/services/BaseCalls";
import { useBerechnungStore } from '@/stores/rechner';
import { useCommonDataStore } from "@/stores/common";
const common = useCommonDataStore();
const rechnerStore = useBerechnungStore();
const { proxy } = getCurrentInstance();
const hunde = ref([]);
const katzen = ref([]);

async function getTarife() {
  let select = {
    action: "getTarife",
    type: "hunde",
    preise: "ja",
    number: "array",
  };
  const resp = await BaseCalls.postTarif(select);
  if (resp.data && resp.data.tarife) {
    hunde.value = resp.data.tarife;
  }
}

async function getTarifeKatze() {
  let select = {
    action: "getTarife",
    type: "katze",
    preise: "ja",
    number: "array",
  };
  const resp = await BaseCalls.postTarif(select);
  if (resp.data && resp.data.tarife) {
    katzen.value = resp.data.tarife;
  }
}

onMounted(() => {
  proxy.$updateDocumentHead(
    "Die besten Tierkrankenversicherungen für die Zahnsteinentfernung beim Hund",
    "Diese Tierkrankenversicherungen leisten für die Zahnsteinentfernung bei Hund und Katze – Jetzt online vergleichen und aktuelle Preise berechnen."
  );
});
getTarife();
getTarifeKatze();
</script>

<style scoped>
.my-4 {
  margin: 16px 0;
}
v-table {
  width: 100%;
}
v-table th, v-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
v-table th {
  background-color: #f2f2f2;
}
</style>